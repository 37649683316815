// 获取折一商品
import axios from '@/service/axios';
import { ObjectFilterEmpty } from '@/tslibs/utils/func';
/** 初选池*/
export const getPrimaryGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods/`, {
    params: ObjectFilterEmpty(params),
  });
};

export const getGoodsDetail = (id, data = {}) => {
  return axios.get(`/api/operators/v1/kuran-goods/${id}/`, { params: data });
};

/** 初审审核*/
export const postPrimarySubmitGoods = data => {
  return axios.post('/api/operators/v1/submit/kuran-goods/first_check/', data);
};

/** 复选池*/
export const getChooseGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods/fist_passed/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 复审审核*/
export const postChooseSubmitGoods = data => {
  return axios.post('/api/operators/v1/submit/kuran-goods/second_check/', data);
};

/** 终审*/
export const getFinalGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods/second_passed/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 终审审核*/
export const postFinalSubmitGoods = data => {
  return axios.post('/api/operators/v1/submit/kuran-goods/final_check/', data);
};

/** 比价*/
export const getCompareGoodsList = params => {
  return axios.get(`/api/operators/v2/submit/compare-kuran-goods/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 更新选品状态 */
export const uploadSelectionGood = (id, params) => {
  return axios.patch(`/api/operators/v1/submit/kuran-goods/check/records/${id}/`, params);
};

/** 上传样品图片*/
export const uploadSampleUrls = (id, data) => {
  return axios.patch(`/api/operators/v1/submit/kuran-goods/${id}/`, data);
};

/** 直播选品记录 */
export const getLiveSelectionGoodsList = (id, params) => {
  return axios.get(`/api/operators/v2/submit/kuran-goods-events/${id}/goods/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 直播选品记录-导出 */
export const exportLiveSelectionGoodsList = (id, params) => {
  return axios.get(`/api/operators/v2/submit/kuran-goods-events/${id}/export-goods/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 招商商品初选池-导出 */
export const exportMerchantPrimaryGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods_export/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 招商商品终审池-导出 */
export const exportMerchantFinalGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods/second_passed_export/`, {
    params: ObjectFilterEmpty(params),
  });
};
/** 招商商品复选池-导出 */
export const exportMerchantChooseGoodsList = params => {
  return axios.get(`/api/operators/v1/submit/kuran-goods/fist_passed_export/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 批量到样*/
export const postSampleSubmitGoods = data => {
  return axios.post('/api/operators/v1/submit/kuran-goods/sample/', data);
};

/** 批量清库*/
export const postClearGoods = data => {
  return axios.post('/api/operators/v1/submit/kuran-goods/clear/', data);
};

/** 快递公司*/
export const getCompanyExpressList = params => {
  return axios.get(`/api/operators/v1/merchant/external/express/`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 选品记录 */
export const getSelectionGoodsList = params => {
  return axios.get(`/api_v2/daren/list_all_check_records`, {
    params: ObjectFilterEmpty(params),
  });
};

/** 招商商品选品记录-导出 */
export const exportMerchantSelectGoodsList = params => {
  return axios.get(`/api_v2/daren/export_all_check_records`, {
    params: ObjectFilterEmpty(params),
  });
};
