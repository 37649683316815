<template>
  <div class="audit-div">
    <div :loading="detailLoading">
      <div class="form-div" ref="detailRef">
        <a-form layout="inline">
          <!--          <a-form-item class="text-item" label="序号:">
            <div class="span-name two">{{ detail_obj.id || '&#45;&#45;' }}</div>
          </a-form-item>-->
          <a-form-item
            class="text-item"
            label="商品名称:"
            style="margin-bottom: 18px; width: 700px"
          >
            <div class="item_name">
              <div class="name" :class="step === 2 || step === 3 ? 'min' : ''">
                {{ detail_obj ? detail_obj.item_name : '' }}
              </div>
              <a-popover
                v-if="step === 2 || step === 3"
                v-model="compareVisible"
                title="比价"
                trigger="click"
              >
                <template #content>
                  <div class="compare-div">
                    <div class="btn-div">
                      <a-input v-model.trim="compare_name" />
                      <a-button
                        :loading="compareLoading"
                        type="primary"
                        size="small"
                        @click="getCompareList"
                        >查询
                      </a-button>
                    </div>
                    <a-table
                      :loading="compareLoading"
                      :pagination="false"
                      :columns="CompareColumn"
                      :data-source="goodsTableData"
                      bordered
                      row-key="id"
                    >
                      <template slot="price" slot-scope="row"
                        >{{ row.price || row.price === 0 ? '￥' + row.price / 100 : '--' }}
                      </template>
                      <template slot="commission_rate" slot-scope="record">
                        {{
                          record.cos_ratio || record.cos_ratio === 0 ? record.cos_ratio + '%' : '--'
                        }}
                      </template>
                      <template slot="operation" slot-scope="record">
                        <a-button
                          type="primary"
                          size="small"
                          @click="goGoodsClick(record.detail_url)"
                          >查看
                        </a-button>
                      </template>
                    </a-table>
                  </div>
                </template>
                <a-button
                  @click="getCompareList"
                  style="height: 24px; line-height: 22px; padding: 0 8px"
                  >比价</a-button
                >
              </a-popover>
            </div>
          </a-form-item>
          <a-form-item class="text-item" label="店铺名称:">
            <div class="span-name two">{{ detail_obj.shop_name || '&#45;&#45;' }}</div>
          </a-form-item>
          <!--   <a-form-item class="text-item" label="店铺类型:">
             <div class="span-name two">
               {{
                 detail_obj.brand_type === 1
                   ? '品牌方'
                   : detail_obj.brand_type === 2
                   ? '代理商'
                   : detail_obj.brand_type === 3
                   ? '黑标'
                   : '&#45;&#45;'
               }}
             </div>
           </a-form-item>
           <a-form-item class="text-item" label="产品类目:">
             <div class="span-name two">{{ detail_obj.merchant_category || '&#45;&#45;' }}</div>
           </a-form-item>-->
          <a-form-item
            class="text-item"
            label="商品链接:"
            style="margin-bottom: 18px; width: 700px"
          >
            <div class="item_name">
              <a-popover title="" trigger="hover">
                <template #content>
                  <div class="compare-div">{{ detail_obj ? detail_obj.item_name : '' }}</div>
                </template>
                <div
                  class="name"
                  style="max-width: 570px"
                  :class="step === 2 || step === 3 ? 'min' : ''"
                >
                  {{ detail_obj ? detail_obj.item_url : '' }}
                </div>
              </a-popover>
              <a-button
                style="margin-left: 8px; height: 24px; line-height: 22px; padding: 0 8px"
                size="small"
                @click="goGoodsClick(detail_obj.item_url)"
                >查看
              </a-button>
            </div>
          </a-form-item>

          <a-form-item label="商品图片:">
            <div class="swiper-images">
              <div v-for="(item, index) in detail_obj.item_id__slide_images" :key="item">
                <div class="pic">
                  <!--                <img :src="item" />-->
                  <my-image
                    :imgWidth="64"
                    :imgUrl="item"
                    :list-url="detail_obj.item_id__slide_images"
                    :select-index="index"
                  />
                </div>
              </div>
            </div>
          </a-form-item>
          <!--          <a-form-item
            v-if="goodsSpecsTemplateRows && goodsSpecsTemplateRows.length > 0"
            label="产品规格:"
          >
            <a-table
              :scroll="{ x: 'max-content', y: 460 }"
              class="goods-size"
              :class="goodsSpecsTemplateRows.length * 42 > 460 ? 'over' : ''"
              :pagination="false"
              :columns="GoodColumn"
              :data-source="goodsSpecsTemplateRows"
              bordered
              :rowKey="record => record.id"
            >
              <template slot="supply_price" slot-scope="row"
                >{{ row.supply_price | thousandsFormat }}
              </template>
            </a-table>
          </a-form-item>

          <a-form-item class="text-item" label="手卡:">
            <div class="span-name two">
              <my-image v-if="getImgUrl(1)" :imgWidth="64" :imgUrl="getImgUrl(1)" />
              <div v-else>&#45;&#45;</div>
            </div>
          </a-form-item>
          <a-form-item class="text-item" label="比价图:">
            <div class="span-name two compare">
              <div class="pic-div" v-if="getImgUrl(2)">
                <my-image :imgWidth="64" :imgUrl="getImgUrl(2)" />
                <a-upload
                  class="upload"
                  v-if="step !== 1 && detailData.record__id"
                  :showUploadList="false"
                  :customRequest="handleFileUpload"
                >
                  <a-button>
                    <a-icon class="fa-window-swap" />
                  </a-button>
                </a-upload>
              </div>
              <div v-else>&#45;&#45;</div>
            </div>
          </a-form-item>
          <a-form-item class="text-item" label="其它资料:">
            <div class="span-name">
              <my-image v-if="getImgUrl(3)" :imgWidth="64" :imgUrl="getImgUrl(3)" />
              <div v-else>&#45;&#45;</div>
            </div>
          </a-form-item>

          <a-form-item class="text-item" label="赠品工具:">
            <div class="span-name two">
              {{ detail_obj.giveaway_tool === 1 ? '已开启' : '未开启' }}
            </div>
          </a-form-item>-->
          <a-form-item v-if="step === 4" class="text-item" label="直播排期:">
            <div class="span-name two">{{ detail_obj.live_date || '--' }}</div>
          </a-form-item>
          <a-form-item v-if="step === 4" class="text-item" label="主播昵称:">
            <div class="span-name two">{{ detail_obj.live_user || '--' }}</div>
          </a-form-item>
          <a-form-item v-if="step === 4" class="text-item" label="达人手机号:">
            <div class="span-name two">{{ detail_obj.daren_mobile || '--' }}</div>
          </a-form-item>
          <a-form-item class="text-item" label="销售价:">
            <div class="span-name two">
              {{
                detail_obj.kuran_price !== null && detail_obj.kuran_price !== undefined
                  ? '￥' + detail_obj.kuran_price
                  : '--'
              }}
            </div>
          </a-form-item>
          <!--          <a-form-item class="text-item" label="公开佣金:">
            <div class="span-name two">
              {{
                detail_obj.item_id__commission_rate !== null &&
                detail_obj.item_id__commission_rate !== undefined
                  ? detail_obj.item_id__commission_rate / 100 + '%'
                  : '&#45;&#45;'
              }}
            </div>
          </a-form-item>-->
          <a-form-item class="text-item" label="达人佣金:">
            <div class="span-name two">
              {{
                detail_obj.item_id__exclusive_commission_rate !== null &&
                detail_obj.item_id__exclusive_commission_rate !== undefined
                  ? detail_obj.item_id__exclusive_commission_rate / 100 + '%'
                  : '--'
              }}
            </div>
            <!--          <a-input disabled v-model="detail_obj.item_id__exclusive_commission_rate"/>-->
          </a-form-item>
          <a-form-item class="text-item" label="团长佣金:">
            <div class="span-name two">
              {{
                detail_obj.item_id__colonel_commission_rate !== null &&
                detail_obj.item_id__colonel_commission_rate !== undefined
                  ? detail_obj.item_id__colonel_commission_rate / 100 + '%'
                  : '--'
              }}
            </div>
            <!--          <a-input disabled v-model="detail_obj.item_id__exclusive_commission_rate"/>-->
          </a-form-item>
          <a-form-item class="text-item" label="价格/机制:">
            <div class="span-name">{{ detail_obj.description || '&#45;&#45;' }}</div>
          </a-form-item>
          <!--  <a-form-item
        v-if="detail_obj.giveaway_tool === 1"
        class="text-item"
        label="赠品价格/机制:"
      >
        <div class="span-name">{{ detail_obj.giveaway_description || '&#45;&#45;' }}</div>
      </a-form-item>
      <a-form-item class="text-item" label="多拍链接:">
        <div class="span-name two">
          {{ detail_obj.is_minimum_per_order === 1 ? '已设置' : '未设置' }}
        </div>
      </a-form-item>
      <a-form-item class="text-item" label="商品库存:">
        <div class="span-name two">{{ detail_obj.stock || '&#45;&#45;' }}</div>
      </a-form-item>
      <a-form-item class="text-item" label="商品ID:">
        <div class="span-name two">
          {{ detail_obj.item_id || '&#45;&#45;' }}
          <a-icon @click="gotoDetail" v-if="detail_obj.item_id" type="link" />
        </div>
      </a-form-item>

      <a-form-item class="text-item" v-if="step === 1" label="店铺口碑分:">
        <div class="span-name two">
          {{
            detail_obj.item_id__merchant_experience_score !== null
              ? detail_obj.item_id__merchant_experience_score
              : ''
          }}
        </div>
      </a-form-item>
      <a-form-item class="text-item" v-if="step === 1" label="店铺体验分:">
        <div class="span-name two">
          {{
            detail_obj.item_id__service_score !== null ? detail_obj.item_id__service_score : ''
          }}
        </div>
      </a-form-item>
      <a-form-item class="text-item" label="发货周期:">
        <div class="span-name two">{{ detail_obj.delivery_cycle || '&#45;&#45;' }}</div>
      </a-form-item>
      <a-form-item class="text-item" label="运费险:">
        <div class="span-name two">{{ detail_obj.freight_policy === 1 ? '有' : '无' }}</div>
      </a-form-item> -->

          <a-form-item class="text-item" label="对接人电话:">
            <div class="span-name two">{{ detail_obj.contact_tel || '--' }}</div>
          </a-form-item>
          <a-form-item class="text-item" label="对接招商:">
            <div class="span-name two">{{ detail_obj.contact__username || '--' }}</div>
          </a-form-item>
          <a-form-item v-if="step !== 1" class="text-item" label="到样日期:">
            <div class="span-name two">{{ detail_obj.sample_date || '&#45;&#45;' }}</div>
          </a-form-item>
          <!--
          <a-form-item class="text-item" label="公司名称:">
            <div class="span-name two">{{ detail_obj.supplier || '&#45;&#45;' }}</div>
          </a-form-item>
          <a-form-item class="text-item" label="是否退样:">
            <div class="span-name two">{{ detail_obj.is_recycle === 1 ? '是' : '否' }}</div>
          </a-form-item>
          <a-form-item v-if="detail_obj.is_recycle === 1" class="text-item" label="退样地址:">
            <div class="span-name">{{ detail_obj.return_address || '&#45;&#45;' }}</div>
          </a-form-item>
          <a-form-item v-if="detail_obj.sample_urls || detailData.sample_urls" label="样品图片:">
            <div class="swiper-images">
              <div v-for="item in detail_obj.sample_urls || detailData.sample_urls" :key="item">
                <div class="pic">
                  &lt;!&ndash;                <my-image :imgWidth="80" imgUrl="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" />&ndash;&gt;
                  <my-image :imgWidth="64" v-if="item" :imgUrl="item" />
                </div>
              </div>
            </div>
          </a-form-item>-->
          <a-form-item v-if="step !== 1" class="text-item" label="活动链接:">
            <div
              class="span-name two"
              v-if="
                step === 3 &&
                (detailData.check_status === 10 ||
                  detailData.check_status === 5 ||
                  detailData.check_status === 8 ||
                  detailData.check_status === 1)
              "
            >
              <a-input
                allow-clear
                style="width: 220px"
                placeholder="请输入活动链接"
                v-model="detailForm.activity_url"
              />
              <a-icon class="copy-icon" type="copy" @click="copyClick(detailForm.activity_url)" />
            </div>
            <div class="span-name two" v-else-if="detail_obj.activity_url">
              已上传
              <a-icon class="copy-icon" type="copy" @click="copyClick(detail_obj.activity_url)" />
            </div>
            <div class="span-name two" v-else>待上传</div>
          </a-form-item>
          <a-form-item class="text-item" label="清库:">
            <div class="span-name two">
              {{ detail_obj.is_clear === 1 ? '是' : detail_obj.is_clear === 0 ? '否' : '--' }}
            </div>
          </a-form-item>
          <div
            class="line-div"
            v-if="
              (detail_obj.second_check_records && detail_obj.second_check_records.length > 0) ||
              (detail_obj.final_check_records && detail_obj.final_check_records.length > 0)
            "
          ></div>
          <a-form-item
            v-if="
              detail_obj.second_check_records &&
              detail_obj.second_check_records.length > 0 &&
              step !== 4
            "
            label="复审记录:"
          >
            <div
              class="record-div"
              v-for="(item, index) in detail_obj.second_check_records"
              :key="item.check_user_id + index"
            >
              <p>
                <span>{{ item.update_time }} </span>
                <span style="margin-left: 15px">{{ item.check_user__username }}</span>
              </p>
              <p>{{ item.check_info }}</p>
            </div>
          </a-form-item>
          <!--          <a-form-item
            v-if="detail_obj.final_check_records && detail_obj.final_check_records.length > 0"
            label="终审记录:"
          >
            <div
              class="record-div"
              v-for="(item, index) in detail_obj.final_check_records"
              :key="item.check_user_id + index"
            >
              <p>
                <span>{{ item.update_time }} </span>
                <span style="margin-left: 15px">{{ item.check_user__username }}</span>
              </p>
              <p>{{ item.check_info }}</p>
            </div>
          </a-form-item>-->
          <template v-if="!readOnly">
            <a-form-item class="text-item" label="选品结果：">
              <a-radio-group name="radioGroup" v-model="detailForm.check_status">
                <a-radio :value="1">通过</a-radio>
                <a-radio :value="2">不通过</a-radio>
                <a-radio :value="3" v-if="step === 2 || step === 3">议价</a-radio>
              </a-radio-group>
            </a-form-item>
            <!--
                  <a-form-item v-if="step === 3 && detailForm.check_status === 1" label="排期/主播：">
              <div>
                <a-date-picker
                  type="datetime"
                  v-model="detailForm.live_date"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  placeholder="选择排期"
                />
                <a-input
                  allow-clear
                  style="margin-left: 12px"
                  placeholder="请输入主播昵称"
                  v-model="detailForm.live_user"
                />
              </div>
            </a-form-item>-->
            <a-textarea
              v-if="step === 1 || step === 2 || detailForm.check_status !== 1"
              v-model="detailForm.check_info"
              :maxLength="100"
              placeholder="请输入审核意见（限100字）"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </template>
          <!--<template v-else>
                        <a-form-item  label="审核结果：">
              <div class="span-name"> {{detail_obj.check_status === 1 ? '已通过' : detail_obj.check_status === 2 ? '不通过' : ''}}</div>
            </a-form-item>
            <a-form-item v-if="detailForm.check_info"  label="审核意见：">
              <div class="span-name"> {{detail_obj.check_info}}</div>
            </a-form-item>
            <a-form-item
              v-if="step === 3 && (detailData.check_status === 10 || detailData.check_status === 5)"
              label="排期/主播："
            >
              <div>
                <a-date-picker
                  type="datetime"
                  v-model="detailForm.live_date"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  placeholder="选择排期"
                />
                <a-input
                  allow-clear
                  style="margin-left: 12px"
                  placeholder="请输入主播昵称"
                  v-model="detailForm.live_user"
                />
              </div>
            </a-form-item>
          </template>-->
        </a-form>
      </div>
      <div class="btn-lines">
        <template v-if="!readOnly">
          <a-button @click="handleHideThisModal()">取消</a-button>
          <a-button type="primary" :loading="isPosting" @click="handleSubmitApplyData"
            >确定
          </a-button>
        </template>
        <template v-if="readOnly">
          <a-button @click="handleHideThisModal()">关闭</a-button>
          <a-button
            v-if="step === 3 && (detailData.check_status === 10 || detailData.check_status === 5)"
            type="primary"
            :loading="isSaveing"
            @click="handleSubmitData(5)"
            >保存
          </a-button>
          <a-button
            v-if="step === 3 && (detailData.check_status === 10 || detailData.check_status === 5)"
            type="primary"
            :loading="isPosting"
            @click="handleSubmitData(9)"
            >结束
          </a-button>
        </template>
        <a-button
          :loading="clearLoading"
          v-if="step === 1 && detailData.first_check_status === 1 && detail_obj.is_clear === 0"
          @click="handleClearData"
          >清库
        </a-button>
        <a-button
          v-if="step === 1 && detailData.first_check_status === 1"
          :loading="printLoading"
          @click="handlePrintData"
          >打印
        </a-button>
      </div>
    </div>
    <a-modal
      :visible="printVisible"
      title="标签打印"
      :ok-button-props="{ disabled: true }"
      :cancel-button-props="{ disabled: true }"
      @ok="handleOk"
      okText="打印"
      class="print-modal"
      @cancel="printVisible = false"
      :width="350"
    >
      <div>
        <a-select
          v-model="detailForm.sample_express_id"
          placeholder="快递公司"
          allowClear
          style="width: 300px; margin-bottom: 12px"
          :default-active-first-option="false"
          show-search
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="getCompanyList"
        >
          <a-select-option v-for="item in companyOptions" :key="item.id" :value="item.id"
            >{{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          allow-clear
          placeholder="请输入物流单号"
          style="width: 300px; margin-bottom: 12px"
          v-model="detailForm.sample_express_number"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import {
  getGoodsDetail,
  postChooseSubmitGoods,
  postPrimarySubmitGoods,
  postFinalSubmitGoods,
  getCompareGoodsList,
  uploadSelectionGood,
  postClearGoods,
  postSampleSubmitGoods,
  getCompanyExpressList,
  uploadSampleUrls,
} from '@/service/merchant';
import { getOssToken } from '@/service/user';
import { generateUUID } from '@/utils';
import axios from 'axios';
import { Modal } from 'ant-design-vue';
import printUtils from '@/utils/print';

export default defineComponent({
  props: {
    detailData: {
      type: Object,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyClick(activity_url) {
      var element = this.createElement(activity_url);
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand('copy');
      element.remove();
      this.$message.success('复制活动链接成功！');
    },
    //创建临时的输入框元素
    createElement(text) {
      var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
      var element = document.createElement('textarea');
      // 防止在ios中产生缩放效果
      element.style.fontSize = '12pt';
      // 重置盒模型
      element.style.border = '0';
      element.style.padding = '0';
      element.style.margin = '0';
      // 将元素移到屏幕外
      element.style.position = 'absolute';
      element.style[isRTL ? 'right' : 'left'] = '-9999px';
      // 移动元素到页面底部
      let yPosition = window.pageYOffset || document.documentElement.scrollTop;
      element.style.top = `${yPosition}px`;
      //设置元素只读
      element.setAttribute('readonly', '');
      element.value = text;
      document.body.appendChild(element);
      return element;
    },
  },
  setup(props, ctx) {
    const tableData = ref([]);
    const goodsTableData = ref([]);
    const compareLoading = ref(false);
    const detail_obj = ref(props.detailData);
    const compare_name = ref(detail_obj.value?.item_name);
    const companyOptions = ref([]);
    const getCompanyList = async (name = '') => {
      compareLoading.value = true;
      const { res } = await getCompanyExpressList({ name });
      compareLoading.value = false;
      console.log(res);
      if (res && res.success) {
        console.log('----', res.data);
        companyOptions.value = res.data;
      }
    };
    const getCompareList = async () => {
      compareLoading.value = true;
      const { res } = await getCompareGoodsList({
        page_size: 20,
        page: 1,
        goods_title: compare_name.value,
      });
      compareLoading.value = false;
      console.log(res);
      if (res && res.success) {
        goodsTableData.value = res.data.products;
      }
    };
    const detailForm = ref({
      check_status: undefined,
      check_info: '',
      live_date: undefined,
      live_user: undefined,
      activity_url: undefined,
      sample_express_number: undefined,
      sample_express_id: undefined,
    });
    const detailLoading = ref(false);
    const getDetail = async () => {
      detailLoading.value = true;
      const { err, res } = await getGoodsDetail(props.detailData.item_id, {
        check_stage: props.step,
        record_id: props.detailData.record__id,
      });
      detailLoading.value = false;
      if (!err) {
        if (res.success) {
          detail_obj.value = res.data;
          detailForm.value.live_date = detail_obj.value.live_date || undefined;
          detailForm.value.live_user = detail_obj.value.live_user || undefined;
          detailForm.value.activity_url = detail_obj.value.activity_url || undefined;
        }
      }
    };
    if (props.step !== 4) {
      getDetail();
    }
    const detailRef = ref();
    watch(
      () => props.detailData,
      () => {
        if (detailRef.value) {
          detailRef.value.scrollTop = 0;
        }
        detail_obj.value = props.detailData;
        compare_name.value = detail_obj.value?.item_name;
        detailForm.value = {
          check_status: undefined,
          check_info: '',
          live_date: undefined,
          live_user: undefined,
        };
        getDetail();
      },
      {
        deep: true,
      },
    );

    const GoodColumn = computed(() => {
      const spec_list = detail_obj.value?.item_id__sku_info?.spec_list || [];
      let width = 0;
      const dyColumns = spec_list.map((el, index) => {
        width = width + 120;
        return {
          id: el.id,
          title: el.name,
          align: 'center',
          width: 120,
          customRender: (text, record) => {
            const spec_id = record?.spec_detail_ids?.[index];
            const spec = spec_list[index];
            const finder = spec.values.find(el => el.id === spec_id);
            return finder ? finder.name : '--';
          },
        };
      });

      return [
        ...dyColumns,
        {
          title: '售价',
          align: 'right',
          fixed: width > 420 ? 'right' : '',
          width: 100,
          customRender: (text, record) => {
            let price = record?.price;
            if (price !== null && price !== undefined) {
              price = '￥' + price / 100;
            } else {
              price = '--';
            }
            return price;
          },
        },
        {
          title: '活动价',
          align: 'right',
          fixed: width > 420 ? 'right' : '',
          width: 100,
          customRender: (text, record) => {
            let price = record?.activity_price;
            if (price !== null && price !== undefined) {
              price = '￥' + price / 100;
            } else {
              price = '--';
            }
            return price;
          },
        },
      ];
    });
    /*[
      {
        title: "口味",
        dataIndex: "out_sku_id",
        width: 160
      },
      {
        title: "包装",
        scopedSlots: { customRender: "supply_price" },
        width: 120
      },
      {
        title: "售价(元)",
        scopedSlots: { customRender: "recommend_price" },
        width: 120
      },
    ];*/

    const CompareColumn = [
      {
        title: '排序',
        dataIndex: 'sort',
        width: 80,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '商品名称',
        dataIndex: 'title',
        ellipsis: true,
        width: 200,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '售价(元)',
        scopedSlots: { customRender: 'price' },
        width: 100,
        align: 'right',
      },
      {
        title: '佣金比例',
        scopedSlots: { customRender: 'commission_rate' },
        width: 120,
        align: 'center',
      },
      {
        title: '商品详情',
        scopedSlots: { customRender: 'operation' },
        width: 100,
        align: 'center',
      },
    ];
    const goGoodsClick = detail_url => {
      window.open(detail_url, '_blank');
    };
    const handleHideThisModal = () => {
      ctx.emit('closeClick');
    };
    const isPosting = ref(false);
    const handleSubmitApplyData = async () => {
      if (detailForm.value.check_status === undefined) {
        ctx.root.$message.warning('请先选择选品结果！！！');
        return;
      }
      if (detailForm.value.check_status !== 1 && !detailForm.value.check_info) {
        ctx.root.$message.warning('请填写选品意见！！！');
        return;
      }
      if (
        detailForm.value.check_status === 1 &&
        props.step === 3 &&
        !detailForm.value.live_date &&
        detailForm.value.live_user
      ) {
        ctx.root.$message.warning('请选择排期！！！');
        return;
      }
      if (
        detailForm.value.check_status === 1 &&
        props.step === 3 &&
        detailForm.value.live_date &&
        !detailForm.value.live_user
      ) {
        ctx.root.$message.warning('请输入主播昵称！！！');
        return;
      }
      /* if(props.step===3 && detailForm.value.check_status===1 && !detailForm.value.live_date){
        ctx.root.$message.warning('请选择排期！！！');
        return;
      }*/
      isPosting.value = true;
      if (props.step === 1) {
        const {
          res: { success, message },
        } = await postPrimarySubmitGoods({
          ...detailForm.value,
          item_id: detail_obj.value.item_id,
          record_id: props.detailData.record__id,
        });
        isPosting.value = false;
        if (success) {
          ctx.root.$message.success('操作成功');
          ctx.emit('reload', false);
          ctx.emit('closeClick');
        } else {
          ctx.root.$message.error(message || '操作失败');
        }
      } else if (props.step === 2) {
        const {
          res: { success, message },
        } = await postChooseSubmitGoods({
          ...detailForm.value,
          item_id: detail_obj.value.item_id,
          record_id: props.detailData.record__id,
        });
        isPosting.value = false;
        if (success) {
          ctx.root.$message.success('操作成功');
          ctx.emit('reload', false);
          ctx.emit('closeClick');
        } else {
          ctx.root.$message.error(message || '操作失败');
        }
      } else if (props.step === 3) {
        const {
          res: { success, message },
        } = await postFinalSubmitGoods({
          ...detailForm.value,
          item_id: detail_obj.value.item_id,
          record_id: props.detailData.record__id,
          activity_url: detailForm.value.activity_url || '',
        });
        isPosting.value = false;
        if (success) {
          ctx.root.$message.success('操作成功');
          ctx.emit('reload', false);
          ctx.emit('closeClick');
        } else {
          ctx.root.$message.error(message || '操作失败');
        }
      }
    };
    const compareVisible = ref(false);
    const hideClick = () => {
      compareVisible.value = false;
    };
    /* const dateOptions = ref([]);
     const getDateList = async ()=> {
       const {err, res} = await getLiveManagementList({
         status: 0
       });
       if (!err) {
         if (res.success) {
           dateOptions.value = res.data.results;
         }
       }
     };
     if(props.step===3) {
       getDateList()
     }*/
    const goodsSpecsTemplateRows = computed(() => {
      const spec_list = (detail_obj.value?.item_id__sku_info?.sku_list || []).map((item, index) => {
        return {
          id: index,
          ...item,
        };
      });
      return spec_list || [];
    });
    const isSaveing = ref(false);
    const handleSubmitData = async (type = 5) => {
      if (
        type === 5 &&
        props.step === 3 &&
        !detailForm.value.live_date &&
        detailForm.value.live_user
      ) {
        ctx.root.$message.warning('请选择排期！！！');
        return;
      }
      if (
        type === 5 &&
        props.step === 3 &&
        detailForm.value.live_date &&
        !detailForm.value.live_user
      ) {
        ctx.root.$message.warning('请输入主播昵称！！！');
        return;
      }
      if (type === 5) {
        isSaveing.value = true;
      } else {
        isPosting.value = true;
      }
      const newType = type === 5 ? (detailForm.value.live_date ? 5 : 10) : type;
      let payload = {
        check_status: newType,
        activity_url: detailForm.value.activity_url || '',
      };
      if (type === 5) {
        payload = {
          live_date: detailForm.value.live_date,
          live_user: detailForm.value.live_user,
          check_status: newType,
          activity_url: detailForm.value.activity_url || '',
        };
      }
      const {
        res: { success, message },
      } = await uploadSelectionGood(props.detailData.record__id, payload);
      if (type === 5) {
        isSaveing.value = false;
      } else {
        isPosting.value = false;
      }
      if (success) {
        ctx.root.$message.success('操作成功');
        ctx.emit('reload', false);
        ctx.emit('closeClick');
      } else {
        ctx.root.$message.error(message || '操作失败');
      }
    };
    const getImgUrl = (type = 1) => {
      if (detail_obj.value.attachment && detail_obj.value.attachment.length > 0) {
        let url = '';
        detail_obj.value.attachment.map(item => {
          if (item.type === type) {
            url = item.url || '';
          }
        });
        return url;
      } else {
        return '';
      }
    };
    const gotoDetail = () => {
      window.open(detail_obj.value.item_url);
    };
    const oss = ref('');
    // 获取osstoken
    const getNewOssToken = async () => {
      const { err, res } = await getOssToken();
      if (!err && res.success) {
        oss.value = res.data;
      }
    };
    getNewOssToken();
    // 文件上传方法
    const handleFileUpload = async file => {
      if (!oss.value || oss.value.expire < new Date().getTime().toString().substring(0, 10))
        await getNewOssToken();
      if (!oss.value) {
        ctx.root.$message.warn('上传失败');
        return false;
      }
      const formData = new FormData();
      const pathArr = file.file.name.split('.');
      const key = `${oss.value.dir}douyin-goods/${generateUUID()}.${pathArr[pathArr.length - 1]}`;
      formData.append('key', key);
      formData.append('policy', oss.value.policy);
      formData.append('signature', oss.value.signature);
      formData.append('success_action_status', '200');
      formData.append('OSSAccessKeyId', oss.value.accessid);
      formData.append('file', file.file);

      axios({
        method: 'post',
        url: oss.value.host,
        data: formData,
      }).then(async response => {
        detail_obj.value.attachment.map(item => {
          if (item.type === 2) {
            item.url = response.config.url + '/' + key || '';
          }
        });
        const {
          res: { success, message },
        } = await uploadSelectionGood(props.detailData.record__id, {
          attachment: detail_obj.value.attachment,
        });
        if (success) {
          ctx.root.$message.success('比价图替换成功');
          ctx.emit('reload', false);
        } else {
          ctx.root.$message.error(message || '操作失败');
        }
      });
    };
    const clearLoading = ref(false);
    const handleClearData = () => {
      Modal.confirm({
        title: () => ' 商品清库',
        content: () => '请确定是否清库？',
        async onOk() {
          clearLoading.value = true;
          const {
            res: { success, message },
          } = await postClearGoods({
            ids: [detail_obj.value.id],
          });
          clearLoading.value = false;
          if (success) {
            ctx.root.$message.success('清库成功！');
            getDetail();
            ctx.emit('reload', false);
          } else {
            ctx.root.$message.error(message || '操作失败');
          }
        },
        icon: false,
        closable: true,
        class: 'clear-modal',
      });
    };
    const printLoading = ref(false);
    const printVisible = ref(false);
    const handlePrintData = async () => {
      if (detail_obj.value.sample_express_number) {
        printLoading.value = true;
        const {
          res: { success, message },
        } = await postSampleSubmitGoods({
          ids: [detail_obj.value.id],
        });
        printLoading.value = false;
        if (success) {
          ctx.root.$message.success('到样成功');
          ctx.emit('reload', false);
          printUtils.printSample([
            {
              code: detail_obj.value.id,
              dp: detail_obj.value.shop_name,
              xh: detail_obj.value.id,
              price: detail_obj.value.kuran_price || '--',
              yj:
                detail_obj.value.item_id__exclusive_commission_rate ||
                detail_obj.value.item_id__exclusive_commission_rate === 0
                  ? detail_obj.value.item_id__exclusive_commission_rate / 100 + '%'
                  : '--',
              tzyj:
                detail_obj.value.item_id__colonel_commission_rate ||
                detail_obj.value.item_id__colonel_commission_rate === 0
                  ? detail_obj.value.item_id__colonel_commission_rate / 100 + '%'
                  : '--',
              ms: detail_obj.value.description || '',
            },
          ]);
          if (location.href !== '') return;
          /*ctx.emit('reload');
          ctx.emit('closeClick');*/
        } else {
          ctx.root.$message.error(message || '操作失败');
        }
      } else {
        getCompanyList();
        printVisible.value = true;
      }
      console.log('----');
    };
    const handleOk = async () => {
      if (!detailForm.value.sample_express_number || !detailForm.value.sample_express_id) {
        ctx.root.$message.warning('请将数据完善！！！');
        return;
      }

      const {
        res: { success, message },
      } = await uploadSampleUrls(detail_obj.value.id, {
        item_id: detail_obj.value.item_id,
        sample_express_company: companyOptions.value.filter(
          item => item.id === detailForm.value.sample_express_id,
        )[0].name,
        sample_express_id: detailForm.value.sample_express_id,
        sample_express_number: detailForm.value.sample_express_number,
      });
      if (success) {
        ctx.root.$message.success('操作成功');
        printVisible.value = false;
        detail_obj.value.sample_express_number = detailForm.value.sample_express_number;
        handlePrintData();
      } else {
        ctx.root.$message.error(message || '操作失败');
      }
    };
    return {
      getCompanyList,
      companyOptions,
      handleOk,
      printVisible,
      printLoading,
      clearLoading,
      handlePrintData,
      handleClearData,
      handleFileUpload,
      isSaveing,
      gotoDetail,
      getImgUrl,
      handleSubmitData,
      compare_name,
      goodsSpecsTemplateRows,
      getCompareList,
      compareLoading,
      goodsTableData,
      // dateOptions,
      goGoodsClick,
      CompareColumn,
      hideClick,
      compareVisible,
      detail_obj,
      detailLoading,
      detailRef,
      getDetail,
      isPosting,
      handleHideThisModal,
      handleSubmitApplyData,
      detailForm,
      GoodColumn,
      tableData,
    };
  },
});
</script>

<style lang="less">
.print-modal .ant-modal-footer {
  text-align: center;
  .ant-btn {
    height: 28px;
    line-height: 26px;
    font-size: 12px;
  }
}

.print-modal .ant-modal {
  margin-top: 100px;
}

.clear-modal {
  .ant-modal-confirm-body > .anticon {
    display: none;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    margin-top: -10px;
  }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-top: 30px;
  }

  .ant-modal {
    margin-top: 100px;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
    .ant-btn {
      height: 28px;
      line-height: 26px;
      font-size: 12px;
    }
  }
}

.compare-div {
  width: 700px;

  .ant-btn-sm {
    font-size: 12px;
  }

  .ant-table-thead tr,
  .ant-table-thead {
    height: 32px !important;
  }

  .ant-table-wrapper {
    overflow: auto;
    max-height: 60vh;
  }

  .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 10px !important;
    font-size: 12px;
    .ant-btn-sm {
      height: 24px;
      line-height: 22px;
    }
  }

  .btn-div {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;

    input {
      width: 500px;
      margin-right: 18px;
      font-size: 12px;
      height: 28px;
    }

    button {
      height: 28px;
      line-height: 26px;
    }
  }
}

.audit-div {
  .pic-div {
    position: relative;
    width: 64px;
    .upload {
      position: absolute;
      top: -12px;
      right: -8px;
      border: none;
      padding: 0;
      background: transparent;
      .ant-btn,
      .ant-upload {
        padding: 0;
        border: none;
        background: transparent;
        .fa-window-swap {
          width: 16px;
          height: 16px;
          z-index: 99;
          background-image: url('../../../../assets/icon-change-compare-gray.png');
          background-size: 100% 100%;
        }
        &:hover {
          .fa-window-swap {
            background-image: url('../../../../assets/icon-change-compare.png');
          }
        }
      }
    }
  }

  .ant-btn,
  .ant-calendar-picker,
  .ant-input {
    height: 28px;
    line-height: 26px;
    font-size: 12px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 10px !important;
    line-height: 20px;
    font-size: 12px;
  }

  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item .ant-form-item-control-wrapper,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control ant-input-affix-wrapper,
  .ant-input,
  .ant-btn,
  .ant-select {
    font-size: 12px;
  }

  .ant-form-item {
    margin-bottom: 8px;
    margin-right: 12px;
  }

  .ant-table-thead tr,
  .ant-table-thead {
    height: 32px !important;
  }
  .ant-form label {
    font-size: 12px;
  }
  .goods-size {
    max-width: 620px;
    max-height: 500px;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px 10px !important;
      font-size: 12px;
    }

    .ant-table-fixed-header .ant-table-scroll .ant-table-header,
    .ant-table-hide-scrollbar {
      margin-right: -10px !important;
    }

    &.over {
      .ant-table-fixed-header .ant-table-scroll .ant-table-header,
      .ant-table-hide-scrollbar {
        margin-right: 0 !important;
      }
    }

    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      overflow-x: auto !important;
      overflow-y: auto !important;
    }

    .ant-table-bordered.ant-table-fixed-header
      .ant-table-scroll
      .ant-table-header.ant-table-hide-scrollbar
      .ant-table-thead
      > tr:only-child
      > th:last-child {
      border-right-color: #e8e8e8;
    }
  }

  .item_name {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
      max-width: 620px;

      &.min {
        max-width: 550px;
      }

      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
    }

    button {
      margin-left: 12px;
      height: 28px;
    }
  }

  .ant-table-wrapper {
    max-height: 500px;

    .ant-table-placeholder {
      height: 110px;
      padding: 0;

      .ant-empty-normal {
        margin: 20px 0 0;
      }
    }
  }

  .swiper-images {
    width: 620px;
    height: 72px;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;

    > div {
      height: 80px;
      margin-right: 12px;

      img {
        height: 80px;
        width: 80px;
      }
    }
  }

  .ant-form .ant-form-item {
    margin-bottom: 0;

    &.text-item {
      margin-bottom: 8px;

      .ant-form-item-label {
        line-height: 28px;
      }
      .ant-form-item-control {
        line-height: 28px;
        min-height: 28px;
        display: flex;
        align-items: center;
      }
    }

    .ant-form-item-label {
      width: 100px;
    }

    .ant-input-affix-wrapper,
    .ant-calendar-picker {
      width: 240px;
      color: #000;
    }

    .span-name {
      width: 620px;
      line-height: 20px;
      .copy-icon {
        margin-left: 4px;
        font-size: 15px;
        color: #999999;

        &:hover {
          color: #e83741;
        }
      }

      &.two {
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.compare {
        overflow: initial;
      }
      .anticon {
        cursor: pointer;

        &:hover {
          color: #e83741;
        }
      }
    }

    .ant-radio-group {
      width: 600px;
    }

    .record-div {
      p {
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 8px;

        &:first-child {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }

      width: 620px;
    }
  }

  .form-div {
    height: calc(100vh - 160px);
    overflow: auto;

    textarea.ant-input {
      margin-left: 100px;
      width: 620px;
    }
  }

  .btn-lines {
    display: flex;
    justify-content: center;
    height: 50px;
    padding-top: 12px;
    align-items: end;

    .ant-btn {
      margin-right: 12px;
      height: 28px;
      line-height: 26px;
      font-size: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .line-div {
    margin: 4px 0;

    height: 1px;
    width: 100%;
    background: #e6e6e6;
  }
}
</style>
