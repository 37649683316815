import Jsbarcode from 'jsbarcode';

const defaultStyle = `html,
body {
  margin: 0;
  padding: 0;
}
.main {
  font-size: 6pt;
  /*border: solid 1px red;*/
}
.good {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
  grid-row-gap: 5px;
  grid-column-gap: 1px;
  padding: 10px 14px;

  overflow: hidden;
}
.good-always {
  page-break-after: always;
}
.good > span,
.good > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.good .bar {
  grid-column-end: span 2;
  height: 50px;
}
.good .bar svg,
.good .bar canvas {
  width: 100%;
  height: 100%;
}
.good .summary {
  grid-column-end: span 2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  height: 6em;
  white-space: normal;
}

.test1 {
  position: absolute;
  left: -10000px;
  top: -1000px;
  width: 0;
  height: 0;
}
.test {
  width: 450px;
  height: 270px;
  z-index: 1000;
  top: 67px;
  left: 240px;
  background: rgb(255, 255, 255);
  position: absolute;
}

`;

const printUtils = {
  printSample(params) {
    if (params === undefined) return;
    const goods = Array.isArray(params) ? params : [params];

    let iframe = document.querySelector('#iframe');
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = 'iframe';
      iframe.style = `  position: absolute;
  left: -10000px;
  top: -1000px;
  width: 0;
  height: 0;`;
      document.body.appendChild(iframe);
    }

    const doc = iframe.contentWindow.document;
    const innersHTML = [];

    innersHTML.push(`<style>${defaultStyle}</style>`);

    goods.forEach((good, index) => {
      const svg = document.createElement('svg');
      let code = good.code + '';
      if (code.length < 32) {
        code = code.padStart(32 - code.length, '0');
      }
      Jsbarcode(svg, code, { displayValue: false, textAlign: 'left', margin: 0 });
      const goodHtml = `<div class="good">
    <div class="bar">${svg.outerHTML}</div>
    <span>店铺: ${good.dp || ''}</span>
    <span>序号: ${good.xh || ''}</span>
    <span>达人佣金: ${good.yj || ''}</span>
    <span>团长佣金: ${good.tzyj || ''}</span>
    <span>销售价: ${good.price || ''}</span>
    <span class="summary">${good.ms}</span>
</div>`;
      innersHTML.push(goodHtml);
      if (index < goods.length - 1) {
        innersHTML.push(`<div class="good-always"></div>`);
      }
    });

    doc.body.innerHTML = `<div class="main">
    ${innersHTML.join('')}
</div>`;

    iframe.contentWindow.print();
    // document.body.removeChild(iframe);

    console.log('---');
  },
};

export default printUtils;
